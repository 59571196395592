<template>
  <div>
    <div :class="containerClass" @click="onWrapperClick">
      <div class="layout-sidebar" @click="onSidebarClick">
        <div class="layout-logo-wrapper">
          <router-link to="/" class="layout-logo-text">
            <span>GESTÃO</span>
          </router-link>
        </div>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>

      <AppTopBar :class="topBarClass" @menu-toggle="onMenuToggle" />
      <div class="layout-main-container">
        <div class="layout-main">
          <router-view />
        </div>
        <AppFooter />
      </div>

      <transition name="layout-mask">
        <div
          v-if="mobileMenuActive"
          class="layout-mask p-component-overlay"></div>
      </transition>
    </div>
  </div>
</template>

<script>
import AppTopbar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppFooter from './AppFooter.vue'
import auth from '@/modules/auth'

export default {
  components: {
    AppTopBar: AppTopbar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      mobileMenuActive: false,
      menu: [],
      atualizandoToken: false,
    }
  },

  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive':
            this.staticMenuInactive && this.layoutMode === 'static',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },

    topBarClass() {
      return [
        {
          'layout-topbar-responsive':
            this.staticMenuInactive && this.layoutMode === 'static',
        },
      ]
    },
  },

  created() {
    this.ativarTimerParaAtualizarToken()
    if (this.tokenExpirado()) {
      this.logout()
    }

    if (auth.hasRoleGestaoEntidade()) {
      this.menu = [
        {
          label: 'Home',
          items: [
            {
              label: 'Servidores',
              icon: 'pi pi-fw pi-users',
              to: { name: 'servidores' },
            },
          ],
        },
        {
          label: 'Sistema',
          icon: 'pi pi-fw pi-sitemap',
          items: [
            {
              label: 'Consignatárias',
              icon: 'pi pi-fw pi-wallet',
              to: { name: 'consignatarias' },
            },
            {
              label: 'Rubricas',
              icon: 'pi pi-fw pi-pencil',
              to: { name: 'rubricas' },
            },
            {
              label: 'Arquivo',
              icon: 'pi pi-fw pi-sitemap',
              items: [
                // {
                //   label: 'Importar Arquivo',
                //   icon: 'pi pi-fw pi-cloud-upload',
                //   to: { name: 'importacoes' },
                // },
                {
                  label: 'Gerar Arquivo Movimento',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'gerar-arquivo-movimento' },
                },
              ],
            },
            {
              label: 'Perfis',
              icon: 'pi pi-tag',
              items: [
                {
                  label: 'Perfis Usuários',
                  icon: 'pi pi-users',
                  to: { name: '' },
                },
                {
                  label: 'Grupos',
                  icon: 'pi pi-globe',
                  to: { name: '' },
                },
                {
                  label: 'Roles',
                  icon: 'pi pi-tag',
                  to: { name: '' },
                },
              ],
            },
          ],
        },
      ]
    }

    if (auth.hasRoleGestao()) {
      this.menu = [
        {
          label: 'Home',
          items: [
            {
              label: 'Servidores',
              icon: 'pi pi-fw pi-users',
              to: { name: 'servidores' },
            },
          ],
        },
        {
          label: 'Sistema',
          icon: 'pi pi-fw pi-sitemap',
          items: [
            {
              label: 'Consignatárias',
              icon: 'pi pi-fw pi-wallet',
              to: { name: 'consignatarias' },
            },
            {
              label: 'Entidades',
              icon: 'pi pi-fw pi-id-card',
              to: { name: 'entidades' },
            },
            {
              label: 'Atividades',
              icon: 'pi pi-fw pi-id-card',
              to: { name: 'atividades' },
            },
            {
              label: 'Órgãos',
              icon: 'pi pi-fw pi-building',
              to: { name: 'orgaos' },
            },
            {
              label: 'Rubricas',
              icon: 'pi pi-fw pi-pencil',
              to: { name: 'rubricas' },
            },
            {
              label: 'Vínculos',
              icon: 'pi pi-fw pi-link',
              to: { name: 'vinculos' },
            },
            {
              label: 'Grupos',
              icon: 'pi pi-fw pi-sitemap',
              to: { name: 'grupos' },
            },
            {
              label: 'Configurações',
              icon: 'pi pi-fw pi-cog',
              to: { name: 'configuracoes' },
            },
            {
              label: 'Usuários',
              icon: 'pi pi-user',
              to: { name: 'usuarios' },
            },
            // {
            //   label: 'Config. perfil de usuário',
            //   icon: 'pi pi-fw pi-user',
            //   to: { name: 'configuracao-perfil' },
            // },
            // {
            //   label: 'Permissões',
            //   icon: 'pi pi-fw pi-globe',
            //   to: { name: 'permissoes' },
            // },
            {
              label: 'Arquivo',
              icon: 'pi pi-fw pi-sitemap',
              items: [
                {
                  label: 'Importar Arquivo',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'importar-arquivo' },
                },
                {
                  label: 'Gerar Arquivo Movimento',
                  icon: 'pi pi-fw pi-cloud-upload',
                  to: { name: 'gerar-arquivo-movimento' },
                },
                // {
                //   label: 'Exportação de Arquivo',
                //   icon: 'pi pi-fw pi-cloud-download',
                //   to: { name: 'exportacoes' },
                // },
              ],
            },
            {
              label: 'Relatórios',
              icon: 'pi pi-fw pi-print',
              to: { name: 'relatorio' },
            },
            {
              label: 'Perfis',
              icon: 'pi pi-tag',
              items: [
                {
                  label: 'Perfis Usuários',
                  icon: 'pi pi-users',
                  to: { name: 'perfilSeguranca' },
                },
                {
                  label: 'Grupos',
                  icon: 'pi pi-globe',
                  to: { name: 'grupoSeguranca' },
                },
                {
                  label: 'Roles',
                  icon: 'pi pi-tag',
                  to: { name: 'role' },
                },
              ],
            },
          ],
        },
        // {
        //   label: 'Usuários',
        //   items: [
        //     {
        //       label: 'Gestão-Entidade',
        //       icon: 'pi pi-user',
        //       to: { name: 'usuarios' },
        //     },
        //     {
        //       label: 'Consignatárias',
        //       icon: 'pi pi-wallet',
        //       to: { name: 'usuariosconsignatarias' },
        //     },
        //     // {
        //     //   label: 'Keycloak',
        //     //   icon: 'pi pi-key',
        //     //   to: { name: 'keycloak-usuarios' },
        //     // },
        //   ],
        // },
      ]
    }
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    tempoRestante() {
      return this.$auth.getParsedToken().exp - Date.now() / 1000
    },

    tokenExpirado() {
      return this.tempoRestante() < 0
    },

    logout() {
      clearInterval(this.timer)
      this.$auth.logout()
      window.location.reload()
    },

    ativarTimerParaAtualizarToken() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.tokenExpirado()) {
            this.logout()
          } else if (this.tempoRestante() < 140) {
            this.$auth.refreshToken()
          }
        }, 70000)
      }
    },

    isDesktop() {
      return window.innerWidth >= 992
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
      this.menuClick = false
    },

    onSidebarClick() {
      this.menuClick = true
      this.overlayMenuActive = false
      this.mobileMenuActive = false
    },

    onMenuToggle() {
      this.menuClick = true
      if (this.isDesktop()) {
        this.staticMenuInactive = !this.staticMenuInactive
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }
      event.preventDefault()
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
  },
}
</script>
